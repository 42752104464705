<template>
  <div class="rtcr">
    <h3 class="mt-4 mb-2">TCR Sample Plans</h3>
    <p><strong>{{ pws_id }} {{ pws_name }}</strong></p>

    <div class="mb-3">
      <b-button variant="primary" to="rtcr-site-list">
        View/Print Current Site Plan
      </b-button>
    </div>

    <p>
      Choose an option below to submit a new TCR Sample Plan. For further instruction, download the <a href="/static/docs/2021-25-RTCRSampleSitePlanOption1-LETTER.pdf" target="_blank">MSDH Letter</a> and <a href="/static/docs/2021-25-RTCRSampleSitePlanOption1-OTHER.pdf" target="_blank">Guidance</a>
  </p>
    <b-list-group class="my-3">
      <b-list-group-item>
        <b-button variant="primary" @click="onOptionOneClick">Option 1</b-button> &nbsp;Resamples will be collected upstream/downstream within 5 service connections.
      </b-list-group-item>
      <!--
      <b-list-group-item>
        <b-button variant="primary" disabled>Option 2</b-button> <strong>(not yet available):</strong> &nbsp;Resamples will be collected from alternative fixed sites.
    </b-list-group-item>
    -->
    <b-list-group-item>
      <b-button @click="onOptionThreeClick" variant="primary">Option 3</b-button>&nbsp;Resamples will be collected from alternative sites which are not fixed and are listed in you Standard Operations Procedures (SOP).
    </b-list-group-item>
  </b-list-group>
  <b-modal @ok="onRTCRModalOK" ref="rtcr-modal" id="rtcr-modal" title="Replace Existing Submission" ok-title="Create New Submission">
    <p>
      There are one or more existing RTCR Site Plan submissions under review (shown under <em>Previous Submissions)</em>.  To submit changes to a current submission, click the corresponding <strong>amend</strong> button.  If you wish to <strong>amend</strong>, <strong>cancel</strong> this dialog.
      <br /><br />
      If you continue to submit a new Site Plan, it may replace any existing submissions.
    </p>
  </b-modal>
  <!--
    <p>
    Submissions for option 2 will be available soon. Only public water systems that choose option 1 or 3 should submit data at this time.
    </p>
    -->
    <div class="mt-4" v-if="submissions.length">
    <h4>Previous Submissions</h4>
    <p>View details about previous RTCR site plan submissions below.</p>
    <b-table striped bordered v-if="submissions.length" :items="submissions" :fields="submission_fields">
      <template #cell(view)="data">
        <b-button size="sm" variant="primary" :to="{ name: 'RTCRReport', params: { plan_id: data.item.plan_id } }">
          ({{ data.item.plan_id }}) View
        </b-button>
      </template>
      <template #cell(amend)="data">
        <b-button size="sm" variant="primary" :to="{ name: 'RTCRSitePlan', query: { amend_plan_id: data.item.plan_id } }">
          Amend 
        </b-button>
      </template>
      <template #cell(status)="data">
          {{ status_map[data.item.status] || data.item.status }}
      </template>

    </b-table>
  </div>

  </div>
</template>
<script>
import { SessionMixin } from "../mixins"

export default {
  name: "RTCR",
  mixins: [SessionMixin],
  data() {
    return {
      option_number: 1,
      submissions: [],
      submission_fields: [
        {key: 'view', label: 'View'},
        {key: 'amend', label: 'Amend'},
        'plan_type',
        'signed_by',
        'status',
        {key: 'submission_ts', label: 'Submission Timestamp'}
      ],
      status_map: {
        'N': 'In Review',
        'F': 'In Review - Followup Required',
        'R': 'Rejected',
        // in SDWIS/STATE
        'A': 'Approved/Pending Updates',
        'S': 'Approved/Complete',
        // replaced by amended
        'X': 'Replaced',
      },
    }
  },
  created() {
    this.fetchSubmissions()
  },
  methods: {
    onOptionOneClick() {
      this.option_number = 1
      const in_review_plan = this.submissions.find((e) => {
        return ['N', 'F'].includes(e.status)
      })
      if (in_review_plan) {
        this.$refs['rtcr-modal'].show()
      }
      else {
        //this.$router.push('/rtcr-site-plan')
        this.$router.push({ path: '/rtcr-site-plan', query: {'option_number': 1}})
      }
    },
    onOptionThreeClick() {
      this.option_number = 3
      const in_review_plan = this.submissions.find((e) => {
        return ['N', 'F'].includes(e.status)
      })
      if (in_review_plan) {
        this.$refs['rtcr-modal'].show()
      }
      else {
        this.$router.push({ path: '/rtcr-site-plan', query: {'option_number': 3}})
      }
    },
    onRTCRModalOK() {
      //this.$router.push('/rtcr-site-plan')
      this.$router.push({ path: '/rtcr-site-plan', query: {'option_number': this.option_number}})
    },
    async fetchSubmissions() {
      const pws_id = this.pws_id
      const path = process.env.VUE_APP_API_URI + "/rtcr-plan-submissions"
      try {
        let response = await this.axios.get(path, {
          params: { pws_id: pws_id }
        })
        console.log(response)
        if (response.data) {
          this.submissions = response.data.plans
        }
      } catch (error) {
        console.log(error)
      }

    }
  }
};
</script>
